input {
  cursor: pointer;
  border: 1px solid silver;
  border-radius: 6px;
  padding: 5px;
  font-size: 100%;
}

label {
  cursor: pointer;
  position: relative;
}

input[type="checkbox"], input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  content: " ";
  width: .8em;
  height: .8em;
  border: 1px solid gray;
  margin: .2em .5em .2em 0;
  line-height: .75em;
  display: inline-block;
}

input[type="radio"] + label:before {
  border-radius: 50%;
}

input[type="checkbox"]:checked + label:before, input[type="radio"]:checked + label:before {
  content: " ";
  background: #696969;
}

input.arrow[type="checkbox"] + label:before {
  color: #aaa;
  margin: 0;
}

input.arrow-l[type="checkbox"] + label:before {
  content: "<";
  border: none;
}

input.arrow-l[type="checkbox"]:checked + label:before {
  content: ">";
  color: #000;
  background: none;
}

input.arrow-r[type="checkbox"] + label:before {
  content: ">";
  border: none;
}

input.arrow-r[type="checkbox"]:checked + label:before {
  content: "<";
  color: #000;
  background: none;
}

input::placeholder {
  text-align: center;
  font-variant: small-caps;
  letter-spacing: .3px;
}

select {
  appearance: none;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0 1em 0 0;
}

select::-ms-expand {
  display: none;
}

.select {
  cursor: pointer;
  max-width: 175px;
  border: 1px solid silver;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 85%;
  display: inline-block;
}

/*# sourceMappingURL=index.04b012d1.css.map */
