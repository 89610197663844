input {
  border-radius: 6px;
  border: 1px solid silver;
  padding: 5px;
  font-size: 100%;
  cursor: pointer;
}

label {
  position: relative;
  cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  content: "\a0";
  display: inline-block;
  margin: 0.2em 0.5em 0.2em 0;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.75em;
  border: 1px solid grey;
}

input[type="radio"] + label::before {
  border-radius: 50%;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  content: "\a0";
  background: dimgray;
}

input.arrow[type="checkbox"] + label::before {
  margin: 0;
  color: #aaa;
}

input.arrow-l[type="checkbox"] + label::before {
  content: "<";
  border: none;
}

input.arrow-l[type="checkbox"]:checked + label::before {
  content: ">";
  background: none;
  color: black;
}

input.arrow-r[type="checkbox"] + label::before {
  content: ">";
  border: none;
}

input.arrow-r[type="checkbox"]:checked + label::before {
  content: "<";
  background: none;
  color: black;
}

input::placeholder {
  text-align: center;
  font-variant: small-caps;
  letter-spacing: 0.3px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
select::-ms-expand {
  display: none;
}

.select {
  cursor: pointer;
  border: 1px solid silver;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 85%;
  display: inline-block;
  max-width: 175px;
}
